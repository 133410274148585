import React from 'react'
import './about.css'
import ME from '../../assets/me-about.jpg'
import {FaAward} from 'react-icons/fa'
import {FaUniversity} from 'react-icons/fa'
import {VscFolderLibrary} from 'react-icons/vsc'


const About = () => {
  return (
    <section id='about'>
      <h5>Get to Know</h5>
      <h2>About Me</h2>

      <div className="container about__container">

        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="About" />
          </div>
        </div>
        <div className="about__content">
          <div className="about__cards">
            <article className='about__card'>
              <FaAward className = 'about__icon'/>
              <h5>Experience</h5>
              <small>2+ Years Coding</small>
            </article>

            <article className='about__card'>
              <FaUniversity className = 'about__icon'/>
              <h5>Education</h5>
              <small>Master's Degree in HCI</small>
            </article>

            <article className='about__card'>
              <VscFolderLibrary className = 'about__icon'/>
              <h5>Projects</h5>
              <small>10+ Completed</small>
            </article>
          </div>
          <p>
          Hello! My name is Matthew Danielsson, an aspiring technical sales professional with a passion for data
           and problem solving. A novice eager to build technical sales skills at an ambitious company, aiming to master 
           lead generation, prospecting, and effective communication to drive the company's growth. I excel in dynamic 
           environments, discovering new business opportunities, and crafting strategies to foster enduring relationships.
          </p>
          <a href="#contact" className='btn btn-primary'>Let's Talk</a>
        </div>
      </div>
    </section>
  )
}

export default About