import React from 'react'
import './footer.css'
import {AiFillLinkedin} from 'react-icons/ai'

const Footer = () => {
  return (
    <footer>
      <a href="#" className='footer__logo'>MDanielssonHCI</a>

      <u1 className='permalinks'>
        <li><a href="#">Home</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#experience">Experience</a></li>
        <li><a href="#services">Services</a></li>
        <li><a href="#portfolio">Portfolio</a></li>
        <li><a href="#contact">Contact</a></li>
      </u1>

      <div className="footer__socials">
        <a href="https://www.linkedin.com/in/matthewdanielsson/"><AiFillLinkedin/></a>
      </div>

     <div className="footer__copyright">
      <small>&copy; MDanielssonHCI Website. All rights reserved. </small>
     </div>


    </footer>
  )
}

export default Footer