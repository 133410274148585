import React from 'react'
import './portfolio.css'
import IMG1 from '../../assets/Austria-IMG.jpg'
import IMG2 from '../../assets/LootBox.PNG'
import IMG3 from '../../assets/SoundSupp.PNG'
import CostOfLiving from '../../assets/Austria vs USA Matthew Danielsson.pdf'
import LootBox from '../../assets/Loot Boxes and Problem Gambling in Adolescents Visualizations.pdf'
import SoundSuppresion from '../../assets/Sound Suppression Effects on Workplace Productivity and Anxiety Presentation.pdf'

const data = [
  {
    id: 1,
    image: IMG1,
    title: 'Differentiating the Cost of Living in Austria and the USA',
    demo: CostOfLiving
    
  },
  {
    id: 2,
    image: IMG2,
    title: 'Loot Boxes and Problem Gambling in Adolescents',
    demo: LootBox
  },
  {
    id: 3,
    image: IMG3,
    title: 'Sound Suppression Effects on Workplace Productivity and Anxiety',
    demo: SoundSuppresion
  },
  
]

const Portfolio = () => {
  return (
    <section id='portfolio'>
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className='container portfolio__container'>
        {
          data.map(({id, image, title, demo}) => {
            return (
              <article key= {id} className='portfolio__item'>
          <div className='portfolio__item-image'>
            <img src={image} alt={title} />
          </div>
          <h3> {title} </h3>
          <div className="portfolio__item-cta">
          <a href={demo} className='btn btn-primary' target='_blank'>Open Project</a>
          </div>
        </article>
            )
          })
        }
      </div>
    </section>
  )
}

export default Portfolio