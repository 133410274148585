import React from 'react'
import './services.css'
import {BiCheck} from 'react-icons/bi'


const Services = () => {
  return (
    <section id='services'>
      <h5>What I Offer</h5>
      <h2>Services</h2>

      <div className="container services__container">
        <article className="service">
          <div className="service__head">
            <h3>UI/UX Design</h3>
          </div>

          <ul className='service__list'>
          <li>
            <BiCheck className='service__list-icon'/>
            <p>Experienced in wireframes, protoyping and user research and feedback systems.</p>
          </li>
          <li>
            <BiCheck className='service__list-icon'/>
            <p>Conducted user research trials to create user-orientated interfaces.</p>
          </li>
          <li>
            <BiCheck className='service__list-icon'/>
            <p>Coded in both Frontend and Backend enviroments to produce UI/UX systems.</p>
          </li>
          <li>
            <BiCheck className='service__list-icon'/>
            <p>Displayed historical letters through XML for the Massachusetts Historical Society.</p>
          </li>
          </ul>
          {/* End of UX/UI */}
        </article>


        <article className='service'>
          <div className="service__head">
            <h3>Web Development</h3>
          </div>

          <ul className='service__list'>
          <li>
            <BiCheck className='service__list-icon'/>
            <p>Created a Database Network for IBM's Open Liberty Project on Calibrated Peer Reviews.</p>
          </li>
          <li>
            <BiCheck className='service__list-icon'/>
            <p>Utilized Docker and MongoDB to create database file containers and file storage systems.</p>
          </li>
          <li>
            <BiCheck className='service__list-icon'/>
            <p>Created system architecture diagrams to connect both Frontend and Backend services.</p>
          </li>
          <li>
            <BiCheck className='service__list-icon'/>
            <p>Collaborated with other teams to create class diagrams, server endpoints and microservice oriented databases.</p>
          </li>
          </ul>
          {/* End of Web Development */}
        </article>

        <article className='service'>
          <div className="service__head">
            <h3>Data Visualization</h3>
          </div>

          <ul className='service__list'>
          <li>
            <BiCheck className='service__list-icon'/>
            <p>Collected and organized large datasets through data filtering programs.</p>
          </li>
          <li>
            <BiCheck className='service__list-icon'/>
            <p>Experienced in Tableau software to create visuals of large datasets.</p>
          </li>
          <li>
            <BiCheck className='service__list-icon'/>
            <p>Presented data findings through storytelling techniques and data visuals.</p>
          </li>
          <li>
            <BiCheck className='service__list-icon'/>
            <p>Participated in project groups to help better represent data finidngs and trends.</p>
          </li>
          </ul>
          {/* End of Data Visualization */}
        </article>
      </div>
    </section>
  )
}

export default Services